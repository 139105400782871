import React, { useEffect, useState } from 'react'
import * as ClikTracker from "../utils/cliktracker";
import { Link, useParams } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { Breadcrumb, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AnimationLoading } from '../components/Lottie';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {FcAndroidOs} from "react-icons/fc";
import { FaApple, FaAppStoreIos, FaLaptop, FaMobile, FaWindows } from "react-icons/fa";
import BarChartJS from '../components/charts/BarChartJS';


function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  }
  

function PageGroupStatstics() {

    const ColumnsForStats = {
        "columns": [
            {
                "field": "id",
                "hide": true
            },
            {
                "field": "name",
                "headerName": "Name",
                "width": 150,
                "editable": false,
                "resizable":true,
				renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="name1">Details of Links</Tooltip>}>
                                            <h6><strong>Detail</strong></h6>
                                        </OverlayTrigger>,

				renderCell: params => {
					const name = params.value.split("$$")[0];
					const long_url  = params.value.split("$$")[1];
					const short_url  = params.value.split("$$")[2];
					
					return <OverlayTrigger placement="bottom" delay={{ show: 1000, hide: 1600 }} 
								overlay={<Tooltip id="name2">
									<div>
										<h6><strong>Details</strong></h6>
										<h6>Name: {name}</h6>
										<h6>Long Link: {long_url}</h6>
										<h6>Short Link: {short_url}</h6>
									</div>
								</Tooltip>}>
								<h6>{name}</h6>
							</OverlayTrigger>
				}
            },
            {
                "field": "totalclicks",
                "headerName": "TC",
                "width": 50,
                "editable": false,
                "resizable":true,
				renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">Total Clicks</Tooltip>}>
                                            <h6><strong>TC</strong></h6>
                                        </OverlayTrigger>
            },
            {
                "field": "uniqueclicks",
                "headerName": "UC",
                "width": 50,
                "editable": false,
                "resizable":true,
				renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="name1">Unique Clicks</Tooltip>}>
                                            <h6><strong>UC</strong></h6>
                                        </OverlayTrigger>,
            },
            {
                "field": "fakeclicks",
                "headerName": "FC",
                "width": 50,
                "editable": false,
                "resizable":true,
				renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="name1">Fake Clicks</Tooltip>}>
                                            <h6><strong>FC</strong></h6>
                                        </OverlayTrigger>,
            },
            // {
            //     "field": "longurl",
            //     "headerName": "Long Link",
            //     "width": 140,
            //     "editable": false,
            //     "resizable":true,
            // },
            // {
            //     "field": "shorturl",
            //     "headerName": "Short Link",
            //     "width": 140,
            //     "editable": false,
            //     "resizable":true,
			// 	renderHeader: params => <h6><strong>Short Link</strong></h6>
            // },
            {
                "field": "desktop",
                "headerName": "Desktop",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">Total Desktop/Computer Clicks</Tooltip>}>
                                            <FaLaptop  size={30}/>
                                        </OverlayTrigger>
            },
            {
                "field": "windows",
                "headerName": "Win",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">Windows Clicks</Tooltip>}>
                                            <FaWindows  size={30}/>
                                        </OverlayTrigger>
            },
            {
                "field": "mac",
                "headerName": "Mac",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">MacOs Clicks</Tooltip>}>
                                            <FaApple  size={30}/>
                                        </OverlayTrigger>
            },
            {
                "field": "mobile",
                "headerName": "📱",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params=> <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">All Mobile Clicks</Tooltip>}>
                                            <FaMobile  size={30}/>
                                        </OverlayTrigger>
            },
            {
                "field": "android",
                "headerName": "Android",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">Andriod Clicks</Tooltip>}>
                                            <FcAndroidOs  size={30}/>
                                        </OverlayTrigger>
            },
            {
                "field": "ios",
                "headerName": "iOS",
                "type": "number",
                "width": 50,
                "editable": false,
                "resizable":true,
                renderHeader: params => <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                                            overlay={<Tooltip id="text2">iOS</Tooltip>}>
                                            <FaAppStoreIos size={30} color="blue"/>
                                        </OverlayTrigger>
            }
        ],
        "rows":[],
        "initialState": {
            "columns": {
                "columnVisibilityModel": {
                    "id": false
                }
            }
        }
    }


    const [loading, setLoading] = useState(true);
    const {groupId} = useParams();
    const [stats, setStats] = useState(null);
    const [labels, setLabels] = useState([]);
    const [values, setValues] = useState([]);
    const [statistics, setStatistics] = useState(ColumnsForStats);
    const topLinkCount = 5;
    const [legends, setLegends] = useState([]);

  
   
      
    useEffect(() => {
        const run = async ()=>{
            try {
                setLoading(true);
                const links = await ClikTracker.getClikTrackerLinks();
                const statistics = await ClikTracker.getGroupStats(groupId);

                if(statistics) {
                    const names = [];
                    const total_clicks = [];
                    const unique_clicks = [];
                    const desktop = [];
                    const android = [];
                    const ios = [];

                    //sort in descending order
                    statistics.links = statistics.links.sort((a,b) => b.total_clicks - a.total_clicks);

                    //get top 5 links
                    statistics.links.forEach((link, index)=>{
                        if(index < topLinkCount){
                            names.push(link.name);
                            total_clicks.push(link.total_clicks); 
                            unique_clicks.push(link.unique_clicks);
                            desktop.push(link.desktop)
                            android.push(link.android)
                            ios.push(link.ios)
                        }
                    });
                    

                    // Statistics Information
                    const statisticsInfo = ColumnsForStats;
                    statisticsInfo["rows"] = statistics.links.map(link=>{
                        const id = link.link;
                        const short_url = links.find(link1 =>link1._id === id)?.short_url;
            
                        return {
                            id: id,
                            name:  `${link.name}$$${link.url}$$${short_url}`,
                            totalclicks: link.total_clicks,
                            uniqueclicks: link.unique_clicks,
                            fakeclicks: link.bots,
                            longurl:  link.url,
                            shorturl: short_url,
                            desktop: link.desktop,
                            windows: link.windows,
                            mac: link.mac,
                            mobile:  link.smartphone + link.tablet + link.phablet + link.phone,
                            android: link.android,
                            ios: link.ios
                        }
                    });

                    setLabels(names);
                    setLegends(["Total Clicks", "Unique Clicks", "Desktop", "Android", "iOS"]);
                    setValues([total_clicks, unique_clicks, desktop, android, ios]);
                    setStats(statistics);
                    setStatistics(statisticsInfo);
                }
            } catch (e) {
                console.log(e.message);
            } finally{
                setLoading(false);
            }
        }   
        run();
    }, [groupId]);

    if(stats === null){
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title="Loading Statistics" /> 
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/groups">Groups</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Statistics</Breadcrumb.Item>
            </Breadcrumb>
            <Container fluid className="centralise">  
                <BarChartJS title={`Top ${topLinkCount} Links`} legends={legends} labels={labels} valuesArray={values}/>
                <br />
                <div style={{ height: 470, width: '100%' }}>
                    <DataGrid {...statistics} loading={loading} components={{Toolbar: CustomToolbar }}/>
                </div>
            </Container>  
        </AppBar>
    )
}

export default PageGroupStatstics
