/**
 
*/

import React, { useState } from "react"
import {BrowserRouter as Router, Switch, Redirect, Route} from 'react-router-dom';
// import PoweredByBOS from "./components/PoweredByBOS";
import ScrollToTop from "./components/ScrollToTop";
import PageLogin from "./pages/PageLogin";
import PageLoginOther from "./pages/PageLoginOther";
import PageSettings from "./pages/PageSettings";
import PageSignUpEmail from "./pages/PageSignUpEmail";
import PagePassword from "./pages/PagePassword";
import PageTerms from "./pages/PageTerm";
import PageClikStatistics from "./pages/PageStatistics";
import PageClikGroups from "./pages/PageGroups";
import PageClikDomains from "./pages/PageDomains";
import PageMyClikLinks from "./pages/PageLinks";
import PageCreateLink from "./pages/PageCreateLink";
import PageGroupEdit from "./pages/PageGroupEdit";
import PageGroupStatstics from "./pages/PageGroupStatstics";
import PageGroupAdd from "./pages/PageGroupAdd";
import PageEditLink from "./pages/PageEditLink";
import PageRotationEdit from "./pages/PageRotationEdit";
import PageRotations from "./pages/PageRotations";
import PageRotationCreate from "./pages/PageRotationCreate";
import PageLinkRedirects from "./pages/PageLinkRedirects";
import PageLinkBlocks from "./pages/PageLinkBlocks";
import PageLinkStats from "./pages/PageLinkStats";
import PageCTA from "./pages/PageCTA";
import PageOAuth from "./pages/PageOAuth";
 
export const ContextUser = React.createContext(null);
export const ContextSetUser = React.createContext(null);
export const ContextShareLink = React.createContext("");
export const ContextSetShareLink = React.createContext(null);
export const ContextReferral = React.createContext("");
export const ContextSetReferral = React.createContext(null);
export const ContextRegisterUrl = React.createContext("");
export const ContextSetRegisterUrl = React.createContext(null);
export const ContextEditingClikTracker = React.createContext(null);
export const ContextSetEditingClikTracker = React.createContext(null);
export const ContextShareText = React.createContext("");
export const ContextSetShareText = React.createContext((link)=>{});

  


function App() {

  const [user, setUser] = useState(null);
  const [shareLink, setShareLink] = useState("");
  const [referral, setReferral] = useState("");
  const [registerUrl, setRegisterUrl] = useState("");
  const [shareText, setShareText] = useState("");
  const [editingClikTracker, setEditingClikTracker] = useState(null);
  

  return (
    <Router>
      <ScrollToTop />
      <Switch>

        <ContextUser.Provider value={user}>
          <ContextSetUser.Provider value={setUser}>
            
            <ContextShareLink.Provider value={shareLink}>
              <ContextSetShareLink.Provider value={setShareLink}>
                
                <ContextReferral.Provider value={referral}>
                  <ContextSetReferral.Provider value={setReferral}>
                  
                  <ContextRegisterUrl.Provider value={registerUrl}>
                  <ContextSetRegisterUrl.Provider value={setRegisterUrl}>
                  
                  <ContextEditingClikTracker.Provider value={editingClikTracker}>
                  <ContextSetEditingClikTracker.Provider value={setEditingClikTracker}>

                  <ContextShareText.Provider value={shareText}>
                  <ContextSetShareText.Provider value={setShareText}>
                   
                   
                    <Route exact path="/" component={PageLogin} />
    
                    {/* Link Pages */}
                    <Route exact path="/create/simple" component={()=><PageCreateLink isSimple={true}/>}/>
                    <Route exact path="/create/advanced" component={()=><PageCreateLink isSimple={false} />}/>
                    <Route exact path="/create/group" component={PageGroupAdd}/>       
                    <Route exact path="/edit/link/:id" component={PageEditLink}/>
                    <Route exact path="/links" component={PageMyClikLinks}/>
                    <Route exact path="/link/statistics" component={PageLinkStats}/>
                    <Route exact path="/link/blocks" component={PageLinkBlocks}/>
                    <Route exact path="/link/redirects" component={PageLinkRedirects}/>
                    
                    <Route exact path="/groups" component={PageClikGroups}/>
                    <Route exact path="/statistics" component={PageClikStatistics}/>
                    <Route exact path="/domains" component={PageClikDomains}/>
                    <Route       path="/group/:groupId" component={PageGroupEdit}/>
                    <Route       path="/stats/:groupId" component={PageGroupStatstics}/>
                    
                    {/* Rotation Pages  */}
                    <Route exact path="/rotations" component={PageRotations}/>
                    <Route exact path="/rotation/create" component={PageRotationCreate}/>
                    <Route       path="/rotation/edit/:id" component={PageRotationEdit}/>

                    {/** ETA Pages */}
                    <Route exact path="/cta" component={PageCTA}/>

                    <Route exact path="/oauth" component={PageOAuth}  />
                    <Route exact path="/signin" component={PageLoginOther} />
                    <Route exact path="/email" component={PageSignUpEmail} />
                    <Route exact path="/password" component={PagePassword} />
                    <Route exact path="/settings" component={PageSettings} />
                    <Route exact path="/terms" component={PageTerms} />
                    <Redirect to="/"/>

                    </ContextSetShareText.Provider>
                  </ContextShareText.Provider>

                    </ContextSetEditingClikTracker.Provider>
                  </ContextEditingClikTracker.Provider>

                  </ContextSetRegisterUrl.Provider>
                  </ContextRegisterUrl.Provider>

                  </ContextSetReferral.Provider>
                  </ContextReferral.Provider>

                  </ContextSetShareLink.Provider>
                  </ContextShareLink.Provider>

                  </ContextSetUser.Provider>
                </ContextUser.Provider>        
                </Switch>
            </Router>
  );
}


export default App;
