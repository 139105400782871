


 import { Bar } from "react-chartjs-2";
 
 const BarChartJS = ({legends, labels, title, valuesArray}) => {

    const backgroundColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
    ]

    const borderColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ];
    
    const barData = {
        labels: labels,
        datasets: legends.map((legend, index) => {return {
            label: legend,
            data: valuesArray[index],
            backgroundColor: backgroundColors[index],
            borderColor: borderColor[index],
            borderWidth: 1
        }})
    };

    const barOptions = { 
        responsive:true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };

    return <><h6><strong>{title}</strong></h6><Bar data={barData} options={barOptions} height={200}/> </>
}

export default BarChartJS