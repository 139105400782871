

import AppBar from '../components/AppBar'
import React, { useEffect, useState } from 'react'
import { Alert, Breadcrumb, Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom'
import { AnimationLoading } from '../components/Lottie';
import * as ClikTracker from "../utils/cliktracker";
import swal from 'sweetalert';
import urlMetadata from 'url-metadata';
import MaterialFormControl from '@mui/material/FormControl';
import { FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
//  import defaultDomains from "../utils/default_domains.json";

function PageEditLink() {

    const { id } = useParams();
    const history = useHistory();
    const [url, setUrl] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState("");
    const [code, setCode] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [description, setDescription] = useState("");
    const [formGroup, setFormGroup] = useState("-1");
    const [formDomain, setFormDomain] = useState("");
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState("");
    const [groups, setGroups] = useState([]);
    const [domains, setDomains] = useState([]);
    const [link, setLink] = useState(null);
    const [loadingGroup, setLoadingGroup] = useState(false);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        const run = async () => {
            try {
                const links = await ClikTracker.getClikTrackerLinks();
                const link = links.find(link => link._id === id);
                if (link) {
                    setName(link.name);
                    setCode(link.short_code);
                    setUrl(link.full_url);
                    setFormDomain(link.domain === "" ? "-1" : link.domain);
                    setTags(link.tags.join(" "));
                    setDescription(link.description);
                    setImage(link.image);
                    setLink(link);
                    setMetaDescription(link.meta_description);
                    setNotes(link.notes);
                }
            } catch (e) {
                console.log(e.message);
            }
        }
        run();
    }, [id]);

    useEffect(() => {
        const run = async () => {

            const groups = await ClikTracker.getGroups();
            setGroups(groups);

            groups.forEach(group => {
                group.links.forEach(linkId => {
                    if (id === linkId) setFormGroup(group._id);
                })
            });

            const defaultDomains = await ClikTracker.getDefaultDomain();


            const domains = await ClikTracker.getDomains();
            setDomains([...defaultDomains, ...domains.map(domain => domain.domain)]);

        }
        run();
    }, [id]);


    const updateUrl = (link) => {
        if (link.match(/^www/gmi)) link = "https://" + link;
        setUrl(link);
    }

    const postUrl = async (e) => {

        e.preventDefault();

        setLoading(true);

        try {

            const expression = /[a-z0-9][a-z0-9-_]{0,61}/gmi;

            if (!e.target.url.value.match(expression)) {
                swal('Clik Tracker', "Please enter a valid link", 'info');
            } else {

                // //async fill in meta data
                // const metadata = await urlMetadata(e.target.url.value);
                // if (metadata.title && name.trim() === "") setName(metadata.title)
                // if (metadata.image) setImage(metadata.image)


                //create of tags
                const temp_tags = e.target.tags.value.replace(/(?:(\s|,))/gim, ",").split(",");
                const tagList = [];
                temp_tags.forEach(t => {
                    if (t.trim() !== "" && tagList.indexOf(t) === -1) {
                        tagList.push(t);
                    }
                });

                //  if(code !== "" && !code.match(/^[a-zA-Z0-9]+$/gmi)){
                //      swal('Clik Track', 'Code should just have letters and/or numbers without any spaces between them', 'info');
                //      setLoading(false);
                //      return;
                //  }

                const link = {
                    full_url: e.target.url.value,
                    name: e.target.name.value,
                    image: image,
                    description: description,
                    meta_description: metaDescription,
                    groupId: formGroup,
                    tags: tagList,
                    track_code: code,
                    domain: formDomain,
                    notes: notes,
                    custom_links: []
                }

                const res = await ClikTracker.updateClikTracker(id, link);
                swal(res.message);
                history.push('/links');
            }
        } catch (e) {
            console.log(e.message);
            swal("Something went wrong");
        } finally {
            setLoading(false);
        }
    }

    const onChangeFormGroup = async (value) => {
        if (value === "add") {
            const name = await swal({
                title: "Add Group",
                text: "Enter the name of your group",
                content: "input",
                buttons: ['CANCEL', 'ADD GROUP']
            });

            if (name) {
                try {
                    setLoadingGroup(true);
                    const res = await ClikTracker.createGroup(name, false, []);

                    const new_groups = await ClikTracker.getGroups();
                    setGroups(new_groups);

                    swal(res.message);
                } catch (e) {
                    swal(`Could not update ${name}`);
                    console.log(e.message);
                } finally {
                    setLoadingGroup(false)
                }
            } else {
                //set Form Group to default
                setFormGroup("-1");
            }
        } else {
            setFormGroup(value);
        }

    }

    if (loadingGroup) {
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title="Loading Group" />
                </Container>
            </AppBar>
        )
    }

    if (loading || link === null || link === undefined) {
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title="Loading Link" />
                </Container>
            </AppBar>
        )
    }

    return (

        <AppBar>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/links">Clik Tracker</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Update Link</Breadcrumb.Item>
            </Breadcrumb>
            <Alert variant="success">
                <h4>Update Your Link Here</h4>
                <br />
                <Form onSubmit={postUrl} className="centralise" style={{ width: "100%" }}>
                    <h6 style={{ float: "left" }}>Long Link</h6>
                    <FormControl required name="url" type="url" value={url} onChange={(e) => updateUrl(e.target.value)} placeholder="Place link here..." />
                    <br />


                    <h6 style={{ float: "left", color: name.length > 50 ? "red" : "" }}>Name Of Link ({name.length}/50)</h6>
                    <FormControl required name="name" type="name" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                    <br />

                    <h6 style={{ float: "left" }}>Meta Description</h6>
                    <FormControl name="metaDescription" value={metaDescription} type="text" onChange={(e) => setMetaDescription(e.target.value)} placeholder="Meta Description" />
                    <br />

                    <h6 style={{ float: "left", color: tags.length > 100 ? "red" : "" }}>Tags ({tags.length}/100)</h6>
                    <FormControl name="tags" type="tags" value={tags} maxLength={100} onChange={(e) => setTags(e.target.value)} placeholder="Tag Name" />
                    <br />

                    <Row xs={2}>
                        <Col>
                            <MaterialFormControl fullWidth>
                                <InputLabel id="group">Group</InputLabel>
                                <Select labelId="group" id="group" name="group" value={formGroup} label="Group" onChange={(e) => onChangeFormGroup(e.target.value)}>
                                    <MenuItem value="-1"><em>DEFAULT</em></MenuItem>
                                    <MenuItem value="add"><em>ADD GROUP</em></MenuItem>
                                    {
                                        groups.map(group => <MenuItem key={group._id} value={group._id}>{group.name}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>Groups created in the group tab</FormHelperText>
                            </MaterialFormControl>
                        </Col>
                        <Col>
                            <MaterialFormControl fullWidth>
                                <InputLabel id="domain">Domain</InputLabel>
                                <Select labelId="domain" id="domain" name="domain" value={formDomain} label="Domain" onChange={(e) => setFormDomain(e.target.value)}>
                                    {
                                        domains.map(domain => <MenuItem key={domain} value={domain}>{domain}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>Select Subdomain to use</FormHelperText>
                            </MaterialFormControl>
                        </Col>
                    </Row>

                    <br />

                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">https://{formDomain}/</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl required type="name" name="code" disabled value={code} onChange={e => setCode(e.target.value)} placeholder={formDomain.domain === process.env.REACT_APP_SHORTEN ? "BOSLinks Generated" : "TrackCode"} />
                    </InputGroup>
                    <br />

                    <textarea style={{ width: "100%", height: 100 }} placeholder="Notes about the link" value={notes} onChange={e => setNotes(e.target.value)} />

                    <br />
                    <br />
                    <Button type="submit" size="lg" className="round hover dropShadow" variant="success">
                        <img src="https://img.icons8.com/ios-filled/25/ffffff/link--v1.png" alt="Link" />
                        {" "}
                        <strong>Update Link</strong>
                    </Button>

                </Form>
            </Alert>
        </AppBar>
    )
}

export default PageEditLink
