



import React from 'react';
import { Modal } from 'react-bootstrap';
import SocialShare from '../SocialShare';


function ModalShare({share, setShare}) {
    return (
        <Modal show={share !== null} onHide={()=>setShare(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Share Tracking Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Long Link: {share?.link}</h6>
                    <h6>Short Link: {share?.shortUrl}</h6>
                    <SocialShare url={share?.shortUrl} title={share?.title} image={share?.image} description=""/>
                </Modal.Body>
            </Modal> 
    )
}

export default ModalShare
