
 
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MAX_NUMBER_OF_CHARACTERS_TO_SHOW= 25;

const Detail = ({text, max}) => {

    const maximum = max?max:MAX_NUMBER_OF_CHARACTERS_TO_SHOW
    if(text && text.length >= maximum){
        
        return <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                    overlay={<Tooltip id="text1">{text}</Tooltip>}>
                    <h6>{text.substring(0, maximum)}...</h6>
                </OverlayTrigger>
    }else{
        return  <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} 
                    overlay={<Tooltip id="text2">{text}</Tooltip>}>
                    <h6>{text}</h6>
                </OverlayTrigger>
    }
}

export default Detail