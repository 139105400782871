import React from 'react';

const PoweredByBOS = () => {
    return (
        <div style={{zIndex:0, position:"fixed", right:10, bottom:10, color:"#8A8A8A"}}>
            <hr/>
            <h6>Powered by <strong>BOS</strong></h6>
        </div>
    )
}

export default PoweredByBOS