/**
 
*/

import React, { useContext, useEffect, useState } from 'react'
import { Container, Button, Modal, Table, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import logo from "../images/logo.png";
import QRCode from 'qrcode';
import swal from 'sweetalert';
import ModalQrCode from './modals/ModalQrCode';
import ModalShare from './modals/ModalShare';
import { AnimationEmpty, AnimationLoading } from './Lottie';
import * as ClikTracker from "../utils/cliktracker";
import Tags from './Tags';
import { Link, useHistory } from 'react-router-dom';
import { ContextSetEditingClikTracker } from '../App';
import { AiFillCopy, AiFillSetting, AiOutlinePlus } from 'react-icons/ai';
import { TextField } from '@mui/material';
import MaterialFormControl from '@mui/material/FormControl';
import { FormHelperText, InputLabel, Select, MenuItem } from '@mui/material';
import iconLogoWords from "../images/words.png";

const iconSize = 20;
const buttonWidth = "80%";


const TableOfLinks = ({ links, setLinks, isRendingGroup }) => {

    const setEditingClikTracker = useContext(ContextSetEditingClikTracker);
    const [modalLink, setModalLink] = useState(null);
    const [search, setSearch] = useState("");
    const [qrCode, setQRCode] = useState(null);
    const [share, setShare] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const history = useHistory();
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("-1");
    const [size, setSize] = useState(window.innerWidth);

    const MOBILE_WIDTH = 800;

    useEffect(() => {
        const updateSize = () => setSize(window.innerWidth)
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const run = async () => {
            try {
                const groups = await ClikTracker.getGroups();
                setGroups(groups);
            } catch (e) {
                console.log(e.message);
            }
        }
        run();
    }, []);



    const onAdd = async () => {
        const result = await swal({
            title: "Clik Tracker",
            text: "Create a new link",
            icon: "info",
            buttons: {
                cancel: "Cancel",
                simple: {
                    text: "Simple Link",
                    value: "simple",
                },
                advanced: {
                    text: "Express Link",
                    value: "advanced",
                },
            },
        });

        if (result) {
            history.push(`/create/${result.toLowerCase()}`)
        }
    }
    const onCopy = (link) => window.navigator.clipboard.writeText(link).then(() => swal('Clik Tracker', `Copied Track link ${link} to clipboard`, 'success'))

    const openModal = (link) => {
        setModalLink(link);
        setEditingClikTracker(link);
    }

    const loadShare = (link) => setShare({ shortUrl: link.short_url, link: link.full_url, image: link.image })

    const loadQRCode = (link) => {
        QRCode.toDataURL(link.short_url, function (err, data) {
            if (err) {
                console.log(err);
                swal(`Cannot generate a QR Code from this link`);
            } else {
                setQRCode({ title: link.name, image: data, shortUrl: link.short_url, url: link.full_url })
            }
        })
    }



    const openDeletePopup = (cliktracker) => {
        swal({
            title: cliktracker.name,
            text: `Do you want to delete ${cliktracker.short_url}\n${cliktracker.full_url}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (result) => {
            if (result) {
                try {
                    setDeleteLoading(true);
                    const res = await ClikTracker.deleteClikTracker(cliktracker._id);
                    swal(res.message);

                    const links = await ClikTracker.getClikTrackerLinks();
                    setLinks(links);
                } catch (e) {
                    console.log(e.message);
                    swal('Could not delete link');
                } finally {
                    setDeleteLoading(false)
                }
            }
        })
    }


    const filter = (link) => {

        //search filter for extesions
        return (link.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            link.tags.indexOf(search) !== -1 ||
            search.replace(/\s/gmi, '') === "")
    }

    //Get group links or all links
    const getLinks = () => {
        return links.filter(link => {
            if (selectedGroup === "-1") {
                return true;
            } else {
                const group = groups.find(group => group._id === selectedGroup);
                if (group) {
                    return group.links.indexOf(link._id) !== -1;
                } else {
                    return false;
                }
            }
        })
    }

    if (links === null || deleteLoading) {
        return <AnimationLoading width={200} title="Loading Clik Tracker..." />
    }

    if (links.length === 0) {
        return (
            <Container>
                <AnimationEmpty title="No Links" width={200} />
                <Button style={{ position: "fixed", bottom: 96, right: 40, zIndex: 10 }} variant="success" size="lg" className="round hover dropShadow" onClick={onAdd}>
                    <AiOutlinePlus size={30} /> ADD
                </Button>
            </Container>
        )
    }

    return (
        <div>
            {isRendingGroup ?
                <>
                    <TextField style={{ width: "60%" }} type="search" label="Search..." variant="outlined" value={search} onChange={e => setSearch(e.target.value)} />
                </> :
                <>
                    <Row xs={2} sm={4}>
                        <Col style={{marginTop:20}}>
                            <img src={iconLogoWords} alt="CLIKTRACKER"  style={{ width: "110%", height:60  }}  />

                        </Col>
                        <Col style={{marginTop:20}}>
                            <TextField  style={{ width: "100%" }} type="search" label="Search..." variant="outlined" value={search} onChange={e => setSearch(e.target.value)} />
                        </Col>
                        <Col style={{marginTop:20}}>
                            <MaterialFormControl  style={{ width: "100%", heigth:18  }} >
                                <InputLabel id="group">Group</InputLabel>
                                <Select labelId="group" id="group" name="group" value={selectedGroup} label="Group" onChange={(e) => setSelectedGroup(e.target.value)}>
                                    <MenuItem value="-1"><em>All</em></MenuItem>
                                    {
                                        groups.map(group => <MenuItem key={group._id} value={group._id}>{group.name}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>Group To Search</FormHelperText>
                            </MaterialFormControl>
                        </Col>
                        <Col style={{marginTop:20}}>
                            <Button  style={{ width: "100%" }} size="lg" variant="success" className="hover dropShadow" onClick={onAdd}>
                                <AiOutlinePlus size={18} /> ADD
                            </Button>
                        </Col>
                    </Row>


                </>
            }

            <br />
            <Table variant="success" className="dropShadow" style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>Name</th>
                        {/* <th>Tags</th> */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getLinks().filter(filter).map((cliktracker, index) =>
                            <tr key={index}>
                                <td>
                                    <OverlayTrigger placement="bottom" delay={{ show: 1000, hide: 1600 }}
                                        overlay={<Tooltip id="tag1">
                                            <div>
                                                <h5>{cliktracker.name}</h5>
                                                <h6>Long Url: {cliktracker.full_url}</h6>
                                                <h6><i>Short Url: {cliktracker.short_url}</i></h6>
                                            </div></Tooltip>}>
                                        <p>{cliktracker.name}</p>
                                    </OverlayTrigger>
                                </td>
                                {/* <td><Tags link={cliktracker} /></td> */}
                                <td>
                                    <Button variant="success" className="round hover dropShadow" onClick={() => onCopy(cliktracker.short_url)}>
                                        <AiFillCopy size={24} />
                                    </Button>
                                    <Button variant="success" className="round hover dropShadow" onClick={() => openModal(cliktracker)}>
                                        <AiFillSetting size={24} />
                                    </Button>
                                </td>
                            </tr>
                        )}
                </tbody>
            </Table>


            <Modal className="centralise" fullscreen={true} show={modalLink !== null} onHide={() => setModalLink(null)}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>Clik Tracker <strong>{modalLink?.name || ""}</strong></h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="centralise">
                        <Row xs={1}>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => onCopy(modalLink.short_url)}>
                                    <img src={logo} width={iconSize} alt="Options" />
                                    <strong>COPY CLIK LINK</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => onCopy(modalLink.full_url)}>
                                    <img src={`https://img.icons8.com/color/${iconSize}/ffffff/shorten-urls.png`} alt="url" />

                                    <strong>COPY LONG URL</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => history.push(`/edit/link/${modalLink._id}`)}>
                                    <img src={logo} width={iconSize} alt="Options" />
                                    <strong>EDIT CLIK LINK</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => loadShare(modalLink)}>
                                    <img src={`https://img.icons8.com/external-vitaliy-gorbachev-flat-vitaly-gorbachev/${iconSize}/ffffff/external-share-social-media-vitaliy-gorbachev-flat-vitaly-gorbachev.png`} alt="share" />

                                    <strong>SHARE CLIK</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => loadQRCode(modalLink)}>
                                    <img src={`https://img.icons8.com/external-vitaliy-gorbachev-fill-vitaly-gorbachev/${iconSize}/000000/external-qr-code-sales-vitaliy-gorbachev-fill-vitaly-gorbachev.png`} alt="qr" />

                                    <strong>MAKE QR CODE</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" as={Link} to="/link/statistics">
                                    <img src={`https://img.icons8.com/external-flatarticons-blue-flatarticons/${iconSize}/ffffff/external-analytics-web-design-and-development-flatarticons-blue-flatarticons.png`} alt="stats" />

                                    <strong>STATISTICS</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" as={Link} to="/link/blocks">
                                    <img src={`https://img.icons8.com/external-justicon-flat-justicon/${iconSize}/ffffff/external-block-notifications-justicon-flat-justicon.png`} alt="block" />

                                    <strong>BLOCK COUNTRIES</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" as={Link} to="/link/redirects">
                                    <img src={`https://img.icons8.com/office/${iconSize}/000000/curly-arrow.png`} alt="curly" />
                                    <strong>REDIRECT COUNTRIES</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width: buttonWidth }} className="hover dropShadow" onClick={() => openDeletePopup(modalLink)}>
                                    <img src={`https://img.icons8.com/material-rounded/${iconSize}/000000/delete-forever.png`} alt="delete" />
                                    <strong>DELETE LINK</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

            {share ? <ModalShare share={share} setShare={setShare} /> : null}
            {qrCode ? <ModalQrCode qrCode={qrCode} setQRCode={setQRCode} /> : null}
        </div>
    )
}

export default TableOfLinks
