import React, { useCallback, useEffect, useState } from 'react'
import * as ClikTracker from "../utils/cliktracker";
import { Link, useParams } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { Breadcrumb, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";
import { AnimationEmpty, AnimationLoading } from '../components/Lottie';
import Detail from '../components/Detail';
import { Chip } from '@mui/material';
import swal from 'sweetalert';
import CurrencyList from 'currency-list'
import MaterialFormControl from '@mui/material/FormControl';
import { FormHelperText, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Help from "../components/Help";
import { AiFillDelete, AiOutlineLink } from 'react-icons/ai';
import { BiMoveVertical } from 'react-icons/bi';


function PageGroupEdit() {

    const { groupId } = useParams();
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState(null);
    const [name, setName] = useState("");
    const [funnel, setFunnel] = useState(false);
    const [links, setLinks] = useState([]);
    const [groupLinks, setGroupLinks] = useState([]);
    const [currency, setCurrency] = useState("-1");
    const [linkCost, setLinkCost] = useState(0);
    const [productCost, setProductCost] = useState(0);
    const [emailLink, setEmailLink] = useState("-1");
    const [openLinks, setOpenLinks] = useState(false);
    const [moveLink, setMoveLink] = useState(null);
    const [move, setMove] = useState("-1");

    // Get Currencies
    useEffect(() => {
        const list = Object.keys(CurrencyList.getAll('en_US'));
        setCurrencies(list.map(key => CurrencyList.getAll('en_US')[key]));
    }, []);


    useEffect(() => {
        const run = async () => {
            try {
                const links = await ClikTracker.getClikTrackerLinks();
                setLinks(links);
            } catch (e) {
                console.log(e.message);
                setLinks([])
            }
        }
        run();
    }, []);


    useEffect(() => {
        const run = async () => {
            try {
                const res = await ClikTracker.getGroup(groupId);
                if (res.group) {
                    setName(res.group.name);
                    setGroup(res.group);
                    setFunnel(res.group.funnel);
                    setGroupLinks(res.group.links);
                }
            } catch (e) {
                console.log(e);
            }
        }
        run();
    }, [groupId]);


    const onToggleLinkToGroup = (cliktrackerId, include) => {

        if (include) {
            const link = groupLinks.find(id => id === cliktrackerId);
            if (!link) {
                setGroupLinks([...groupLinks, cliktrackerId]);
            }
        } else {
            const list = [];
            groupLinks.forEach(id => {
                if (id !== cliktrackerId) {
                    list.push(id);
                }
            })
            setGroupLinks(list);
        }
    }


    const onUpdate = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const name = e.target.name.value;
            const res = await ClikTracker.updateGroup(groupId, name, funnel, groupLinks);
            swal('Clik Tracker', res.message, 'info');
        } catch (e) {
            swal(`Could not update ${name}`);
            console.log(e.message);
        } finally {
            setLoading(false)
        }
    }

    const getGroupLinks = useCallback(() => {
        const list = [];
        const ids = links.map(l => l._id);
        groupLinks.forEach(link => {
            const index = ids.indexOf(link);
            if (index !== -1) list.push(links[index]);
        });
        return list;
    }, [links, groupLinks]);


    const onRearrangeLinks = () => {
        if (move === "-1") return swal('Move Link', 'Please select a link to move above to', 'info');

        const index = groupLinks.findIndex(id => id === move);

        const list = [];
 
        groupLinks.forEach((id, count) => {
            if (index === count) {
                list.push(moveLink._id);
                list.push(move);
            } else if (id !== moveLink._id) list.push(id);
        });

        setGroupLinks(list);
        setMoveLink(null);
    }

    if (group === null || loading) {
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title={`Loading ${name}`} />
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>
            <Breadcrumb>
                <Breadcrumb.Item href="#"><Link to="/groups">Groups</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>{name}</Breadcrumb.Item>
            </Breadcrumb>
            <Container className="centralise">

                <Form onSubmit={onUpdate}>

                    <TextField required type="name" name="name" label="Group Name" value={name} onChange={e => setName(e.target.value)} />
                    <Button size="lg" variant="success" className="hover dropShadow" type="submit">
                        <strong>UPDATE</strong>
                    </Button>
                    <br />
                    {/* <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={(e) => setFunnel(e.target.checked)} checked={funnel} />}  label="Is Funnel" />
                    </FormGroup> */}
                    <br />
                    {
                        !funnel ? null :
                            <>
                                <h6>Cost For Link<Help>The amount of money invested to create the add for your campaign</Help></h6>
                                <Row xs={2}>
                                    <Col>
                                        <MaterialFormControl fullWidth>
                                            <InputLabel id="currency">Curreny</InputLabel>
                                            <Select labelId="currency" id="currency" name="currency" value={currency} label="Currency" onChange={(e) => setCurrency(e.target.value)}>
                                                <MenuItem value="-1" disabled><em>Choose Currency</em></MenuItem>
                                                {
                                                    currencies.map((c, index) =>
                                                        <MenuItem key={index + index} value={c.code}>
                                                            {`${c.name} ${c.code}`}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                            <FormHelperText>Curreny</FormHelperText>
                                        </MaterialFormControl>
                                    </Col>
                                    <Col>
                                        <TextField InputProps={{ inputProps: { min: 0 } }} style={{ width: 120 }} type="number" label="Amount" variant="outlined" value={linkCost} onChange={e => setLinkCost(e.target.value)} />
                                    </Col>
                                </Row>
                                <br />

                                <h6>Email Collected Link<Help>The link people go to when they submit their email</Help></h6>
                                <MaterialFormControl fullWidth>
                                    <InputLabel id="email">Email Collected Link</InputLabel>
                                    <Select labelId="email" id="email" name="email" value={emailLink} label="Email Collected Link" onChange={(e) => setEmailLink(e.target.value)}>
                                        <MenuItem value="-1" disabled><em>NONE</em></MenuItem>
                                        {
                                            links.map((link, index) => <MenuItem key={index + link._id} value={link.name}>{currency.name}</MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText>Email Collected Link</FormHelperText>
                                </MaterialFormControl>
                                <br />

                                <h6>Price of Product<Help>The link people go to after they purchase the product</Help></h6>
                                <TextField InputProps={{ inputProps: { min: 0 } }} style={{ width: 120 }} type="number" label={`Product Cost ${currency === "-1" ? "" : `(${currency})`}`} variant="outlined" value={productCost} onChange={e => setProductCost(e.target.value)} />
                            </>
                    }
                    <br />
                    <h6>LINKS
                        <Help>These are the links that are in your group, funnel or campaign</Help>
                        {" "}
                        <Button variant="success" className="round hover dropShadow" onClick={() => setOpenLinks(true)}>
                            <strong><AiOutlineLink /> Add Link</strong>
                        </Button>
                    </h6>
                    {
                        getGroupLinks().length === 0 ?
                            <Container>
                                <AnimationEmpty width={200} title="No Links Added" />
                            </Container>

                            :

                            <Table variant="success" className="dropShadow">
                                <thead>
                                    <tr>
                                        <th>NAME</th>
                                        <th>LINKS</th>
                                        <th>ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getGroupLinks().map(cliktracker =>
                                        <tr key={cliktracker._id}>
                                            <td><Detail text={cliktracker.name} /></td>
                                            <td>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="tag1">{cliktracker.short_url}</Tooltip>}>
                                                    <Chip label="SHORT URL" />
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="tag1">{cliktracker.full_url}</Tooltip>}>
                                                    <Chip label="LONG URL" />
                                                </OverlayTrigger>
                                            </td>
                                            <td>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="buttonMove">Move Link</Tooltip>}>
                                                    <Button variant="success" className="round hover dropShadow" onClick={() => setMoveLink(cliktracker)}>
                                                        <BiMoveVertical /> MOVE
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                                    overlay={<Tooltip id="buttonDel">Remove Link</Tooltip>}>
                                                    <Button variant="dark" className="round hover dropShadow" onClick={() => onToggleLinkToGroup(cliktracker._id, false)}>
                                                        <AiFillDelete />  DEL
                                                    </Button>
                                                </OverlayTrigger>

                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                    }
                    <br />
                    <button id="group" style={{ display: "none" }} type="submit" />
                </Form>
            </Container>
            <br /><br /><br /><br />

            <Modal size="lg" show={openLinks} onHide={() => setOpenLinks(false)} aria-labelledby="modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">GROUP LINKS</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Table variant="success" className="dropShadow">
                        <thead>
                            <tr>
                                <th>INCLUDE</th>
                                <th>NAME</th>
                                <th>LINKS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {links.map(cliktracker =>
                                <tr key={cliktracker._id}>
                                    <td><Switch onChange={(value) => onToggleLinkToGroup(cliktracker._id, value)} checked={groupLinks.indexOf(cliktracker._id) !== -1} width={45} height={25} /></td>
                                    <td><Detail text={cliktracker.name} /></td>
                                    <td>
                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="tag1">{cliktracker.short_url}</Tooltip>}>
                                            <Chip label="SHORT URL" />
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="tag1">{cliktracker.full_url}</Tooltip>}>
                                            <Chip label="LONG URL" />
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={moveLink} onHide={() => setMoveLink(null)} aria-labelledby="modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="modal-title">MOVE </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        moveLink ?
                            <>
                                <MaterialFormControl fullWidth>
                                    <InputLabel id="move">Links</InputLabel>
                                    <Select labelId="move" id="move" name="move" value={move} label="Which Link" onChange={(e) => setMove(e.target.value)}>
                                        <MenuItem value="-1"><em>Choose Link to Move Above</em></MenuItem>
                                        {
                                            getGroupLinks().map(link => <MenuItem key={link._id + "move"} value={link._id}>{link.name}</MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText>Which Link to Move Above</FormHelperText>
                                </MaterialFormControl>
                            </>
                            : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setMoveLink(null)}>
                        CANCEL
                    </Button>
                    <Button variant="success" onClick={onRearrangeLinks}>
                        MOVE
                    </Button>
                </Modal.Footer>
            </Modal>
        </AppBar>
    )
}

export default PageGroupEdit
