import React, { useEffect, useState } from 'react'
import { Button, Container, FormControl, Table } from 'react-bootstrap';
import { AiFillCopy, AiFillDelete, AiFillEdit, AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import AppBar from '../components/AppBar';
import Detail from '../components/Detail';
import { AnimationLoading } from '../components/Lottie';
import * as ClikTracker from "../utils/cliktracker";


function PageRotations() {

    const [rotators, setRotators] = useState(null);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    useEffect(() => {
        async function run() {
            try {
                setLoading(true);
                const list = await ClikTracker.getRotators();
                setRotators(list);
            } catch (e) {
                console.log(e.message);
            } finally {
                setLoading(false);
            }
        }
        run();
    }, [])

    const onCopy = (link) => navigator.clipboard.writeText(link).then(() => swal('Link Copied', `Copied Rotating Link`, 'success'))



    const onDel = async (id) => {
        try {
            const result = await swal({
                title: `Delete Rotator`,
                text: `Are you sure you want to delete this?`,
                icon: 'info',
                buttons: ['Cancel', `Delete`],
            });


            if (result) {
                setLoading(true);
                const res = await ClikTracker.deleteRotator(id);
                if (res.result) {
                    const list = await ClikTracker.getRotators();
                    setRotators(list);
                    swal('Clik Tracker Rotators', res.message, " success");
                } else {
                    swal('Clik Tracker Rotators', res.message, " info");
                }
            }

        } catch (e) {
            console.log(e.message);
        } finally {
            setLoading(false);
        }
    }

    const filter = (rotation) => {

        //search filter for extesions
        return (rotation.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            rotation.code.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            search.replace(/\s/gmi, '') === "")
    }

    if (loading || rotators === null || rotators === undefined) {
        return (
            <div>
                <AppBar>
                    <AnimationLoading title="Loading Link Rotators..." />
                </AppBar>
            </div>
        )
    }

    return (

        <AppBar>
            <Container>

                <br />
                <FormControl type="search" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search..." />
                <Table variant="success" className="dropShadow" style={{ width: "80%" }}>
                    <thead>
                        <tr>
                            <td>NAME</td>
                            <td>LINKS</td>
                            <td>URL</td>
                            <td>ACTIONS</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rotators.filter(filter).map(rotation =>
                            <tr key={rotation._id}>
                                <td>{rotation.name}</td>
                                <td>{rotation.urls.length}</td>
                                <td><Detail text={rotation.url} max={25} /></td>
                                <td>
                                    <Button variant="success" className="hover dropShadow" onClick={() => onCopy(rotation.url)}>
                                        <AiFillCopy />
                                    </Button>
                                    <Button variant="success" className="hover dropShadow" as={Link} to={`/rotation/edit/${rotation._id}`}>
                                        <AiFillEdit />
                                    </Button>
                                    <Button variant="success" className="hover dropShadow" onClick={() => onDel(rotation._id)}>
                                        <AiFillDelete />
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Button style={{ position: "fixed", bottom: 100, right: 40, zIndex: 1 }} variant="success" size="lg" className="round hover dropShadow" as={Link} to="/rotation/create">
                    <AiOutlinePlus size={30} /> ADD
                </Button>
            </Container>
            <br /><br /><br /><br />
        </AppBar>


    )
}

export default PageRotations
