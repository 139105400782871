import React, {  useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Container} from 'react-bootstrap';
import swal from 'sweetalert';
import * as ClikTracker from "../utils/cliktracker";
import { AnimationLoading } from '../components/Lottie';
import MaterialFormControl from '@mui/material/FormControl';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CountriesAndCities from "countries-cities/main";
import { Box } from '@mui/material';
import lookup from 'country-code-lookup';
import AppBar from '../components/AppBar';
import { Link } from 'react-router-dom';
import { ContextEditingClikTracker } from '../App';
import {SiAdblock} from "react-icons/si";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, locations, theme) {
  return {
    fontWeight:
      locations.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function PageLinkBlocks() {

    const editingClikTracker = useContext(ContextEditingClikTracker);
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const handleChange = (event) => {
      const { target: { value } } = event;
      setLocations(typeof value === 'string' ? value.split(',') : value);
    };

    
    useEffect(() => {
        //load blocked countries
        const locations = editingClikTracker.blocked_countries.map(country_code=>lookup.byInternet(country_code).country)
       setLocations(locations);
    }, [editingClikTracker]);



    const onSet = async () => {
        try{
            setLoading(true);
            const data = typeof locations === 'string' ? [locations] : locations;
            const countries = data.map(country => lookup.byCountry(country).internet);
            const res = await ClikTracker.updateClikTrackerBlocks(editingClikTracker._id, countries);
            swal('Blocked Countries', res.message, 'success');
        }catch(e){
            console.log(e.message);
            swal('Blocked Countries',`Check your internet and try again`, 'info');
        }finally{
            setLoading(false);
        }
        
    }

    const filter = (name)=>{
        return lookup.byCountry(name) !== null && lookup.byCountry(name) !== undefined;
    }

    if(loading){
        return (
            <div>
                <AppBar>
                    <Container>
                        <AnimationLoading title="Loading..."/>
                    </Container>
                </AppBar>
            </div>
        )
    }


    return (
        <AppBar>
           
            <Container className="centralise">
                <Breadcrumb>
                    <Breadcrumb.Item href="#"><Link to="/links">Clik Tracker</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{editingClikTracker.name} Blocks</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div className="centralise">
                    <Button variant="success" className="round hover dropShadow" onClick={onSet}>
                        <SiAdblock />
                        <strong>Add Blocked Countries</strong>
                    </Button>
                </div>
                <br/>
                <MaterialFormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-chip-label">Locations</InputLabel>
                        <Select labelId="demo-multiple-chip-label" id="demo-multiple-chip" multiple value={locations} onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Locations" />}
                            renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                <Chip key={value} label={value} />
                                ))}
                            </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {CountriesAndCities.getCountries().filter(filter).map((name) => (
                            <MenuItem  key={name} value={name} style={getStyles(name, locations, theme)} >
                                {name}
                            </MenuItem>
                            ))}
                        </Select>
                    </MaterialFormControl>
            </Container>
        </AppBar>
    )
}

export default PageLinkBlocks
