import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack'

function Tags({link}) {

    if(!link){
        return  <Chip label="NO TAGS" />
    }

    if(link.tags.length === 0){
        return  <Chip label="NO TAGS" />
    }else if(link.tags.length === 1){
        return <Chip label={link.tags[0]} />
    }else if(link.tags.length === 2){
        return <Stack direction="row" spacing={1}>
                    <Chip label={link.tags[0]}/>
                    <Chip label={link.tags[1]} />
                </Stack>
    }else{
        return <Stack direction="row" spacing={1}>
                    <Chip label={link.tags[0]}/>
                    <Chip label={link.tags[1]} />
                    <Chip label={link.tags[2]} />
                </Stack>
    }
}

export default Tags
