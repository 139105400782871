


import React from 'react'
import { Line } from 'react-chartjs-2'

const LineChart = ({labels, values, title}) => {

    const lineData = {
        
        labels: labels,
        datasets: [{
            label: title,
            fill:false,
            showLine:true,
            data: values,
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 4
        }]
    
    }

    const lineOptions = {
    
        responsive:true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{
                stacked: true
            }]
        }
    }

    return <><h6><strong>{title}</strong></h6><Line data={lineData} options={lineOptions} height={200}/></>
}

export default LineChart
