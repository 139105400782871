/**
 
*/
 
import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Container } from 'react-bootstrap'
import BarChart from '../components/charts/BarChart'
import LineChart from '../components/charts/LineChart';
import PieChart from '../components/charts/PieChart'
import { ContextEditingClikTracker } from '../App';
import * as ClikTracker from "../utils/cliktracker";
import { AnimationLoading } from '../components/Lottie';
import AppBar from '../components/AppBar';
import { Link } from 'react-router-dom';


function PageLinkStats() {

    const [days, setDays] = useState([]);
    const [dailyClicks, setDailyClicks] = useState([])
    const [countries, setCountries] = useState([]);
    const [countryClicks, setCountriesClicks] = useState([]);
    const editingClikTracker = useContext(ContextEditingClikTracker);
    const [stats, setStats] = useState(null);


    useEffect(() => {

        async function run (){
            try{
                const stats = await ClikTracker.getClikTrackerStatistics(editingClikTracker._id);
                if(stats){
                    
                    const labels = [];
                    const values = [];
                    stats.daily.forEach(day=>{
                        labels.push(day.date);
                        values.push(day.clicks)
                    });
            
                    setDailyClicks(values);
                    setDays(labels);
            
                    const countries_labels = [];
                    const countries_values = [];
                    stats.countries.forEach(country=>{
                        countries_labels.push(country.country);
                        countries_values.push(country.clicks);
                    });
            
                    setCountries(countries_labels)
                    setCountriesClicks(countries_values);
                    setStats(stats);
                }
            }catch(e){
                console.log(e.message);
            }finally{

            }
        }
        run()
        
    }, [editingClikTracker])

    if(!stats){
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title="Loading Stats"/>
                </Container>
            </AppBar>
        )
    }


    return (
        <AppBar>
           
            <Container className="centralise" fluid="sm" style={{padding:50}}>
                <Breadcrumb>
                    <Breadcrumb.Item href="#"><Link to="/links">Clik Tracker</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{editingClikTracker.name} Statistics</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <BarChart title="Stats" labels={["Total Clicks", "Unique Clicks",]} values={[stats.total_clicks, stats.unique_clicks]}/>
                <br/>
                <PieChart labels={["Mac", "Windows",  "IOS", "Android"]} values={[stats.mac, stats.windows,  stats.ios, stats.android]}/>
                <br/>
                <LineChart title="Daily Clicks (30days)" labels={days} values={dailyClicks}/>
                <br/>
                <PieChart title="Countries" labels={countries} values={countryClicks}/>
                <br /><br /><br /><br />
            </Container>
        </AppBar>
    )
}

export default PageLinkStats
