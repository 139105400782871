import React, {useState} from 'react'
import { FaLayerGroup,FaGlobeEurope, FaLink, FaListAlt, FaPowerOff} from 'react-icons/fa';
import { MdRotateRight, MdMoreVert } from 'react-icons/md';
import { BiStats} from 'react-icons/bi';
import { HiSpeakerphone } from 'react-icons/hi';
import { AiFillSetting } from 'react-icons/ai';
import { Container,  Button, Modal, Row, Col} from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';


function MobileBar() {

    const [popup, setPopup] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const buttonWidth = "80%";
   
    const style ={
       display:"flex", 
       background:"#292929",
       position:"fixed",
       width:"100%",
       height:"40px",
       bottom:0,
       left:0,
       zIndex:9999,
       color:'ghostwhite',
       padding:7,
       justifyContent: "center"
    
    }

    const onDashboard = () => {
        const access_token = Cookies.get(process.env.REACT_APP_COOKIE_KEY);
        const url = `${process.env.REACT_APP_DASHBOARD_URL}/oauth?appToken=${access_token}&appName=Clik-Tracker`;
        window.open(url, "_blank");
    }

    const onLogout = () => {
        Cookies.set(process.env.REACT_APP_COOKIE_KEY, null);
        history.push("/");
        // swal("Logout", 'Come back again', 'success');
    }

    const getSelectedColor = (path) => {
        return location.pathname.indexOf(path) !== -1 ? '#6aff94' : '#E6E6E6';
    };


    

  return (
    <div className="centralise dropShadow" style={style}>
        <div style={{paddingLeft:10}} onClick={()=>history.push('/links')}>
            <FaLink size={20} color={getSelectedColor('links')} />
        </div>
        <div style={{paddingLeft:25}} onClick={()=>history.push('/groups')}>
            <FaLayerGroup size={20} color={getSelectedColor('groups')} />
        </div>
        <div style={{paddingLeft:25}} onClick={()=>history.push('/statistics')}>
            <BiStats size={20} color={getSelectedColor('statistics')} />
        </div>
        <div style={{paddingLeft:25}} onClick={()=>history.push('/domains')}>
            <FaGlobeEurope size={20} color={getSelectedColor('domains')}/>
        </div>
        <div style={{paddingLeft:25}} onClick={()=>history.push('/rotations')}>
            <MdRotateRight size={20} color={getSelectedColor('rotations')} />
        </div>
        <div style={{paddingLeft:25}} onClick={()=>setPopup(true)}>
            <MdMoreVert size={20} />
        </div>

        <Modal className="centralise" style={{width:"70%"}} show={popup} onHide={()=>setPopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><h6>More Options</h6></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="centralise">
                        <Row xs={1}>
                            <Col>
                                <Button variant="light" style={{ width:buttonWidth}} className="hover dropShadow" onClick={()=>history.push('/cta')}>
                                    <HiSpeakerphone />
                                    <strong>Call to Action</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width:buttonWidth}} className="hover dropShadow" onClick={onDashboard}>
                                    <FaListAlt />
                                    <strong>Dashboard</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width:buttonWidth}} className="hover dropShadow" onClick={()=>history.push(`/settings`)}>
                                    <AiFillSetting />
                                    <strong>Settings</strong>
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="light" style={{ width:buttonWidth}} className="hover dropShadow" onClick={onLogout}>
                                    <FaPowerOff />
                                    <strong>Logout</strong>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
    </div>
  )
}

export default MobileBar