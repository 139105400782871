/*  */


import React from 'react'
import { Col, Row,Button, Container } from 'react-bootstrap';
import { FacebookShareButton, LinkedinShareButton, RedditShareButton, TumblrShareButton, TelegramShareButton, TwitterShareButton, WeiboShareButton, WhatsappShareButton, FacebookIcon, LinkedinIcon, RedditIcon, TelegramIcon, TumblrIcon, TwitterIcon, WeiboIcon, WhatsappIcon } from 'react-share';

function SocialShare({url, title, image, description}) {

    const onSocialShare = (platform) => console.log("Shared on "+ platform)

    return (
        <Container>
            <Row xs={4}>

            {/** React Share */}
            <Col>
                <FacebookShareButton windowWidth={1024} windowHeight={700} url={url} quote="" hashtag="" beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('Facebook')}>
                    <Button variant="light" className="hover dropShadow"><FacebookIcon size={30} style={{borderRadius:"50%"}}/></Button>
                </FacebookShareButton>
            </Col>


            <Col>
                <LinkedinShareButton windowWidth={1024} windowHeight={700} url={url}  title={title} summary={description} source="" beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('LinkedIn')}>
                    <Button variant="light" className="hover dropShadow"><LinkedinIcon size={30} style={{borderRadius:"50%"}}/></Button>  
                </LinkedinShareButton>
            </Col>

            <Col>
                <RedditShareButton  windowWidth={1024} windowHeight={700} url={url} title={title} beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('Reddit')}>
                    <Button variant="light" className="hover dropShadow"><RedditIcon size={30}/></Button> 
                </RedditShareButton>
            </Col>

            <Col>
                <TelegramShareButton windowWidth={1024} windowHeight={700} url={url}  title={title} beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('Telegram')}>
                    <Button variant="light" className="hover dropShadow"><TelegramIcon size={30} style={{borderRadius:"50%"}}/></Button> 
                </TelegramShareButton>
            </Col>


            <Col>
                <TumblrShareButton windowWidth={1024} windowHeight={700} url={url}  title={title} tags={["hashtag1","hastag2"]} caption="" beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('Tumblr')}>
                    <Button variant="light" className="hover dropShadow"><TumblrIcon size={30} style={{borderRadius:"50%"}}/></Button> 
                </TumblrShareButton>
            </Col>


            <Col>
                <TwitterShareButton windowWidth={1024} windowHeight={700} url={url}  title={title} hashtags={["hashtag1","hastag2"]} related={[""]} beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare('Twitter')}>
                    <Button variant="light" className="hover dropShadow"><TwitterIcon size={30} style={{borderRadius:"50%"}}/></Button>
                </TwitterShareButton>
            </Col>


            <Col>
                <WeiboShareButton  windowWidth={1024} windowHeight={700} url={url} title={title} image={image} beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare("Weibo")}>
                    <Button variant="light" className="hover dropShadow"><WeiboIcon size={30} style={{borderRadius:"50%"}}/></Button>
                </WeiboShareButton>
            </Col>

            <Col>
                <WhatsappShareButton windowWidth={1024} windowHeight={700}  url={url} title={title} beforeOnClick={()=>null} openShareDialogOnClick={true} onShareWindowClose={()=>onSocialShare("Whatsapp")}>
                    <Button variant="light" className="hover dropShadow"><WhatsappIcon size={30} style={{borderRadius:"50%"}}/></Button>
                </WhatsappShareButton>
            </Col>                   
            </Row>

        </Container>
       
    )
}

export default SocialShare
