/* eslint-disable no-unused-vars */
/*  */
import React, { useEffect, useState } from 'react'
import { Button, Container, Form, FormControl, Modal, Table, Alert } from 'react-bootstrap';
import AppBar from '../components/AppBar';
import {AiFillFileAdd, AiOutlinePlus} from "react-icons/ai";
import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import swal from "sweetalert";
import defaultDomains from "../utils/default_domains.json";
import * as ClikTracker from "../utils/cliktracker";
import Help from '../components/Help';


const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  }));
  
  const actions = [
      { icon: <AiFillFileAdd size={26}/>, name: 'Add'},
    ];

    

function PageDomain() {

    const [domains, setDomains] = useState([]);
    const [popup, setPopup] = useState(false);
    const [search, setSearch] = useState("");
   
    useEffect(() => {
        async function run(){
            const list = await ClikTracker.getDomains();
            setDomains(list);
        }
        run();
    }, []);

    
    async function onAddDomain (e) {
        e.preventDefault();
        const domain  = e.target.domain.value;

        if(!domain.match(/([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/gmi)){
            swal('Clik Tracker', 'Please enter Domain', 'info');
            return;
        }

        if(defaultDomains.find(d=>d === domain)){
            swal('Clik Tracker', 'Sorry you can\'t use that domain', 'info');
            return ;
        }

        if(!domains.find(d => domain === d)){
            const res = await ClikTracker.createDomain(domain, true);
            if(res.result){
                const list = await ClikTracker.getDomains();
                setDomains(list);
                setPopup(false);
                swal('Clik Tracker', res.message, 'success');
            }
        }
        
    }

    const onDelete = async (domain) => {
  
        const result = await swal({
            title: `Delete Domain`,
            text: `Are you sure you want to delete this?`,
            icon: 'info',
            buttons: ['Cancel', `Delete`],
        });


        if (result) {
            const res = await ClikTracker.deleteDomain(domain._id);
            if (res) {
                const list = await ClikTracker.getDomains();
                setDomains(list);
                swal('Clik Tracker', 'Domain Deleted', 'success');
            }
        }
    } 

    const filter = (domain) => {

        //search filter for extesions
        return  (domain.domain.toLowerCase().indexOf(search.toLowerCase()) !== -1 || 
                search.replace(/\s/gmi, '') === "")
    }

    const onOpenTab = (url) => window.open(url, "_blank");
    


    return (
        <AppBar>
            <br/>
            <Alert variant="success" className="centralise">
                <Alert.Heading>
                    <h5>Add an <strong>A Record</strong> to domain or subdomain <strong>{process.env.REACT_APP_A_RECORD}</strong>
                        <Help>
                            <h6>How to add am <strong>A Record</strong> watch <a href="/#" onClick={()=>onOpenTab("https://www.youtube.com/watch?v=VhJMv1sgxlw")}>this video</a></h6>
                            <h6>Instructions For <a href="/#" onClick={()=>onOpenTab("https://www.youtube.com/watch?v=jtp3xyxOtjs")}>Hostgator</a></h6>
                            <h6>Instructions For <a href="/#" onClick={()=>onOpenTab("https://www.youtube.com/watch?v=VhJMv1sgxlw")}>Blue Host</a></h6>
                            <h6>Instructions For <a href="/#" onClick={()=>onOpenTab("https://www.youtube.com/watch?v=VFfW2tGkvh0")}>Cpanel</a></h6>
                        </Help>
                    </h5>
                    <h6>NOTE: After changing <strong>A Records</strong> it might take up to 24hours to take effect.</h6>
                </Alert.Heading>
            </Alert>
            <br/>
            <Container>
                <FormControl type="search" value={search} onChange={e=>setSearch(e.target.value)} placeholder="Search..."/>
                <br/>
                <Table variant="success" className="dropShadow">
                    <thead>
                        <tr>
                            <th>DOMAINS</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            domains.filter(filter).map((domain)=>
                                <tr key={domain._id}>
                                    <td>{domain.domain}</td>
                                    <td> <Button variant="dark" className="round hover dropShadow" onClick={()=>onDelete(domain)}> DEL </Button> </td>
                                </tr>    
                            )
                        }
                    </tbody>

                </Table>
            </Container>
            <Button style={{position:"fixed", bottom: 96, right:40, zIndex:10}} variant="success" size="lg" className="round hover dropShadow" onClick={()=>setPopup(true)}>
                <AiOutlinePlus size={30}/> ADD
            </Button>
            
            
            <Modal show={popup} onHide={()=>setPopup(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Domain</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={onAddDomain}>
                            <Form.Label><h5>Domain</h5></Form.Label>
                            <FormControl required name="domain" className="round" maxLength={200} placeholder="Domain e.g me.subdomain.com" type="link"/>
                            <button id="button-add" style={{display:"none"}} type="submit"></button>
                        </Form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={()=>setPopup(false)}><strong>CLOSE</strong></Button>
                    <label htmlFor="button-add">
                        <Button variant="success" onClick={()=>document.getElementById('button-add').click()}><strong>ADD</strong></Button>
                    </label>
                </Modal.Footer>
            </Modal>
        </AppBar>
    )
}

export default PageDomain
