
import React, { useEffect, useState } from 'react'

// Documentation - https://react-accessible-accordion.springload.co.nz/
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import TableOfLinks from '../components/TableOfLinks';
import * as ClikTracker from "../utils/cliktracker";
import { AnimationEmpty, AnimationLoading } from '../components/Lottie';
import { Button, Container } from 'react-bootstrap';
import swal from 'sweetalert';
import AppBar from "../components/AppBar"
import { Link } from 'react-router-dom';
import { AiOutlinePlus, AiFillDelete } from 'react-icons/ai';
import { MdEdit } from "react-icons/md";
import { BiDoughnutChart } from "react-icons/bi";
import iconLogoWords from "../images/words.png";


function PageClikGroups() {


    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useState(window.innerWidth);
    const MIN_WIDTH = 800;

    useEffect(() => {
        const updateSize = () => setSize(window.innerWidth)
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        const run = async () => {
            try {
                setLoading(true);
                const groups = await ClikTracker.getGroups();
                setGroups(groups);
            } catch (e) {
                console.log(e.message);
            } finally {
                setLoading(false);
            }

        }
        run();
    }, []);


    const GroupLinks = ({ group }) => {

        const [links, setLinks] = useState(null);

        useEffect(() => {
            const run = async () => {
                try {
                    const res = await ClikTracker.getGroup(group._id)
                    setLinks(res.links);
                } catch (e) {
                    console.log(e.message);
                    setLinks([])
                }
            }
            run();
        }, [group]);

        if (links === null) {
            return <AnimationLoading title={"Loading " + group.name + " links"} />
        }

        return <TableOfLinks links={links} setLinks={setLinks} isRendingGroup={true} />

    }


    const onDelGroup = async (group) => {
        const result = await swal({
            title: group.name,
            text: `Do you want to delete ${group.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });

        if (result) {
            try {
                setLoading(true);
                const res = await ClikTracker.deleteGroup(group._id);
                swal(res.message);

                const groups = await ClikTracker.getGroups();
                setGroups(groups);
            } catch (e) {
                swal('Could not delete group');
            } finally {
                setLoading(false)
            }
        }
    }


    if (loading) {
        return (
            <AppBar>
                <Container>
                    <AnimationLoading title="Loading Groups..." />
                </Container>
            </AppBar>
        )
    }

    return (
        <AppBar>

            {
                groups.length === 0 ? <>
                    <AnimationEmpty title="No Groups" width={200} />
                    <br />
                    <Button size="lg" variant='outline-success' className='round hover dropShadow' as={Link} to="/create/group">
                        <AiOutlinePlus size={30} /> ADD GROUP / FUNNEL
                    </Button>

                </> :
                    <>
                        <div style={{ display: "flex" }}>
                            <Button variant="success" className="hover dropShadow" as={Link} to="/create/group">
                                <AiOutlinePlus size={20} /> ADD GROUP
                            </Button>
                            {" "}
                            <div style={{position:"absolute", top:3 ,right:5}}>
                            <img src={iconLogoWords} alt="CLIKTRACKER" style={{ marginLeft:14, width: 140 }} />
                            </div>
                            
                        </div>
                        <br />
                        <Accordion allowZeroExpanded>
                            {
                                groups.map((group) =>

                                    <AccordionItem key={group._id}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <strong>{group.name}</strong>
                                                {" "}
                                                <Button variant="success" className="hover round dropShadow" as={Link} to={`/group/${group._id}`}>
                                                    <MdEdit size={24} />
                                                    {" "}
                                                    {size >= MIN_WIDTH ? <strong>EDIT</strong> : null}
                                                </Button>
                                                {" "}
                                                <Button variant="success" className="hover round dropShadow" as={Link} to={`/stats/${group._id}`}>
                                                    <BiDoughnutChart size={24} />
                                                    {" "}
                                                    {size >= MIN_WIDTH ? <strong>STATS</strong> : null}
                                                </Button>
                                                {" "}
                                                <Button variant="dark" className="hover round dropShadow" onClick={() => onDelGroup(group)}>
                                                    <AiFillDelete size={24} />
                                                    {" "}
                                                    {size >= MIN_WIDTH ? <strong>DEL</strong> : null}
                                                </Button>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <GroupLinks group={group} />
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                )}
                        </Accordion>
                    </>
            }
            <br /><br /><br /><br />


        </AppBar>
    )
}

export default PageClikGroups
