import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function ToolTip({text, children}) {
  return (
    <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id={Math.random()}>{text}</Tooltip>}>
        <div style={{float:"right"}}>{children}</div>
    </OverlayTrigger>
  )
}

export default ToolTip