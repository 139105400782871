import React from 'react';
import { Container } from 'react-bootstrap';
import AppBar from '../components/AppBar';
import { AnimationComingSoon } from '../components/Lottie';


function PageCTA() {
    return (
        <AppBar>
            <Container>
                <AnimationComingSoon/>
            </Container>
        </AppBar>
    )
}

export default PageCTA
