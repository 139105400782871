import React from 'react'
import { Container } from 'react-bootstrap'
import AppBar from '../components/AppBar'

function PageTerms() {

    const url = "https://app.mysocial360.com/terms";
    
    return (
        <AppBar>
            <Container>
            <iframe title="Privacy Policy" src={url}  width={550} height={510} >
            </iframe>
            </Container>
            
        </AppBar>
    )
}

export default PageTerms
