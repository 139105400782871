/**
 
*/

import React, { useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import logo from "../images/logo.png";
import { useHistory, useLocation } from 'react-router-dom';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import Cookies from 'js-cookie'; 
import { ContextSetReferral, ContextSetRegisterUrl, ContextSetShareLink, ContextSetShareText, ContextSetUser } from '../App';

//https://v5.reactrouter.com/web/example/query-parameters
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PageOAuth() {

    const history = useHistory();
    const setUser = useContext(ContextSetUser);
    const appToken = useQuery().get('appToken');
    const setShareLink = useContext(ContextSetShareLink);
    const setRegisterUrl = useContext(ContextSetRegisterUrl);
    const setReferral = useContext(ContextSetReferral);
    const setShareText = useContext(ContextSetShareText);
   
    useEffect(() => {
        const run = async ()=>{

            try{
                Cookies.set(process.env.REACT_APP_COOKIE_KEY, appToken); 
                const data = await API.GetAPI('/api/user');
                const {user, share_url, referral, register_url, share_text} = data;
                setUser(user);
                setShareLink(share_url);
                setRegisterUrl(register_url);
                setReferral(referral ? referral : "");
                setShareText(share_text);
                history.push('/links');
            }catch(e){
                history.push('/');
            }
        }
        run()
    }, [setUser, setShareLink,setReferral, setRegisterUrl, setShareText, history, appToken])


    return (
        <Container style={{paddingTop:140, paddingBottom:40}} className="centralise" fluid>
                <img src={logo} alt="logo" width={300}/>
                <h4>Logging In</h4>
                <AnimationLoading width={200} title="Loading"/>
        </Container>
    )
}

export default PageOAuth
