import React from 'react'
import AppBar from '../components/AppBar'
import ContentSettings from '../content/ContentSettings'

function PageSettings() {
    return (
        <div>
            <AppBar>
                <ContentSettings/>
            </AppBar>
            
        </div>
    )
}

export default PageSettings
