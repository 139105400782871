 /* eslint-disable no-undef */
/**
 
*/

import axios from "axios";
import Cookies from 'js-cookie';


const instance = axios.create();

export function getAccessToken(){
    return Cookies.get(process.env.REACT_APP_COOKIE_KEY);
}

export function createClikTrackerLink(payload){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.post(`${url}/api/cliktracker`, payload, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function getClikTrackerLinks(){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/cliktracker`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getClikTrackerStatistics(cliktrackerID){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/cliktracker/${cliktrackerID}/stats`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getStatistics(){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/cliktracker/stats/all`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function updateClikTracker(id, updatedClikTracker){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.patch(`${url}/api/cliktracker/${id}`, updatedClikTracker, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    })
}

export function updateClikTrackerRedirects(cliktrackerId, redirectsArray){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.patch(`${url}/api/cliktracker/redirects/${cliktrackerId}`, {redirects:redirectsArray}, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function updateClikTrackerBlocks(cliktrackerId, blocksArray){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.patch(`${url}/api/cliktracker/blocks/${cliktrackerId}`, {blocks: blocksArray}, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function deleteClikTracker(id){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.delete(`${url}/api/cliktracker/${id}`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    })
}

export function createDomain(domain, ssl){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.post(`${url}/api/domain`, {domain: domain, ssl:ssl}, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function getDefaultDomain(){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/domain/default`, {headers});
            resolve(res.data?res.data:[]);
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getDomains(){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/domain`, {headers});
            resolve(res.data?res.data:[]);
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function deleteDomain(domainId){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.delete(`${url}/api/domain/${domainId}`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve({message:"Could not remove domain"});
        }
    }) 
}

export function createGroup(name, funnel, links=[]){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.post(`${url}/api/group`, {name: name, links:links, funnel:funnel}, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function updateGroup(groupId, name, funnel, links){
    return new Promise(async resolve=>{
        try {
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.patch(`${url}/api/group/${groupId}`, {name: name, links:links, funnel:funnel}, {headers});
            return resolve(res.data)
        } catch(e) {
            console.log(e.message);
            return resolve(null);
        }
    }) 
}
 
export function getGroups(){
    return new Promise(async resolve=>{
        try {
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/group/all`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getGroupStats(groupId){
    return new Promise(async resolve=>{
        try {
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/group/${groupId}/stats`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getGroup(groupId){
    return new Promise(async resolve=>{
        try {
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/group/${groupId}/single`, {headers});
             return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function deleteGroup(id){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.delete(`${url}/api/group/${id}`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    })
}

export function getRotators(){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/rotator`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve([]);
        }
    }) 
}

export function getRotator(id){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.get(`${url}/api/rotator/${id}`, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function postRotator(payload){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.post(`${url}/api/rotator`, payload, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}

export function updateRotator(id, payload){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.patch(`${url}/api/rotator/${id}`, payload, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}


export function deleteRotator(id){
    return new Promise(async resolve=>{
        try{
            const url = process.env.REACT_APP_BACKEND;
            const token = await getAccessToken();
            const headers = {'Authorization': 'Bearer ' + token};
            const res = await instance.delete(`${url}/api/rotator/${id}`, payload, {headers});
            return resolve(res.data)
        }catch(e){
            console.log(e.message);
            return resolve(null);
        }
    }) 
}