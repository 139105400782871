



import React from 'react'
import { Container, Modal } from 'react-bootstrap'

function ModalQrCode({qrCode, setQRCode}) {
    return (
        <Modal show={qrCode !== null} onHide={()=>setQRCode(null)}>
        <Modal.Header closeButton>
            <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container className="centralise">
                <h4>{qrCode?.title}</h4>
                <h5>{qrCode?.shortUrl}</h5>
                <hr/>
                <img src={qrCode?.image} className="dropShadow" alt="qrcode"/>
            </Container>  
        </Modal.Body>
    </Modal>
    )
}

export default ModalQrCode
