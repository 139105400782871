

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import AppBar from '../components/AppBar';
import { AnimationLoading } from '../components/Lottie';
import TableOfLinks from '../components/TableOfLinks';
import * as ClikTracker from "../utils/cliktracker";

const PageMyClikLinks = () => {

    const [links, setLinks] = useState(null);

    useEffect(() => {
        const run = async () => {
            try {
                const links = await ClikTracker.getClikTrackerLinks();
                setLinks(links);
            } catch (e) {
                console.log(e.message);
                setLinks([])
            }
        }
        run();
    }, []);

    if (links === null) {
        return <AppBar>
            <AnimationLoading title="Loading Clik Tracker..." />
        </AppBar>

    }


    return <AppBar>
        <Container fluid>
            <TableOfLinks links={links} setLinks={setLinks} />
            <br /><br /><br /><br />
        </Container>
    </AppBar>
}

export default PageMyClikLinks
