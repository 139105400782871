


 import React from 'react'
import { Doughnut } from 'react-chartjs-2';


const PieChart = ({labels, values, title}) => {

    const pieData = {
        
        datasets: [{
            data: values,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            
            borderWidth: 1
        }],
    
        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: labels, 
    };

    const pieOptions = {
        responsive:true,
        maintainAspectRatio: true,
        cutoutPercentage: 50, //donut
    };

return <><h6><strong>{title}</strong></h6><Doughnut data={pieData} options={pieOptions} height={200}/></>;

}

export default PieChart
