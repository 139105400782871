import React, { useContext, useState } from 'react'
import { Button, Col, Container, Form, FormControl, Row } from 'react-bootstrap';
import logo from "../images/logo.png";
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { ContextSetReferral, ContextSetRegisterUrl, ContextSetShareLink, ContextSetShareText, ContextSetUser } from '../App';
import { AnimationLoading } from '../components/Lottie';
import * as API from "../utils/api";
import Cookies from 'js-cookie';



function PageLoginOther() {

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const setUser = useContext(ContextSetUser);
    const setShareLink = useContext(ContextSetShareLink);
    const setRegisterUrl = useContext(ContextSetRegisterUrl);
    const setReferral = useContext(ContextSetReferral);
    const setShareText = useContext(ContextSetShareText);
 
    const onEmail = async (e) => {
        e.preventDefault();
        try{
            setLoading(true);
            const user = {
                email: e.target.email.value,
                password: e.target.password.value,
            }

            const res = await API.PostAPI('/api/email/oauth', user);
            if(res.result){
                const access_token = res.access_token;
                Cookies.set(process.env.REACT_APP_COOKIE_KEY, access_token); 
                const data = await API.GetAPI('/api/user');
                const {user, share_url, referral, register_url, share_text} = data;
                setUser(user);
                setShareLink(share_url);
                setRegisterUrl(register_url);
                setReferral(referral ? referral : "");
                setShareText(share_text)
                swal('Sign In', res.message, 'success');
                history.push('/links');
                 
            }else{
                swal('Sign In', 'Something happened. Check your internet connection', 'info');
            }
        }catch(error){
            console.log(error.message);
        }finally{
            setLoading(false);
        }
    }
 
    return (
        <Container style={{paddingTop:60}} fluid="sm">
                <div className="centralise" >
                    <img src={logo} alt="logo" width={250}/>
                </div>
                
                {loading ? 
                <div className="app"><AnimationLoading width={180} title="Loading"/></div>
                
                :
                
                <div> 
                    <br/>
                    <Form onSubmit={onEmail}>
                        <Form.Label>Email</Form.Label>
                        <FormControl placeholder="Your Email" type="email" required name="email" aria-label="email" />
                        <br/>
                        <Form.Label>Password</Form.Label>
                        <FormControl placeholder="Your Password" type="password" required name="password" aria-label="password"/>
                        <br/>
                        <Button disabled={loading} type="submit" variant="outline-success" className="round hover dropShadow">
                            <strong>Login With Email</strong>
                        </Button>
                        <br/><br/>
                        <Row xs={1}>
                            <Col>
                                <h6>No <Link to="/email">Account?</Link></h6>
                            </Col>
                            <Col>
                                <h6>Forgot <Link to="/password">Password?</Link></h6>
                            </Col>
                        </Row>
                    </Form>
                    <br/><br/>
                    <div>
                        <h6>Back to Continue With <Link to="/">Facebook or Google</Link></h6>
                    </div>
                </div>  
                }

                <br/><br/><br/><br/>
        </Container>
    )
}

export default PageLoginOther
