/* */

import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Container, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ContextEditingClikTracker } from '../App';
import AppBar from '../components/AppBar';
import * as ClikTracker from "../utils/cliktracker";
import swal from 'sweetalert';
import { AnimationLoading } from '../components/Lottie';
import CountriesAndCities from "countries-cities/main";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import lookup from 'country-code-lookup';
import ReactCountryFlag from 'react-country-flag';
import { AiFillDelete } from 'react-icons/ai';
import { BiDirections } from 'react-icons/bi';
 
function PageLinkRedirects() {

    const editingClikTracker = useContext(ContextEditingClikTracker);
    const [redirects, setRedirects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState("");
    const [country, setCountry] = useState("-1");

    //load redirect countries url
    useEffect(() => {
        setRedirects(editingClikTracker.redirect_countries);
    }, [editingClikTracker]);


    const onSet = async () =>{
        try{
            setLoading(true);
            const res = await ClikTracker.updateClikTrackerRedirects(editingClikTracker._id, redirects);
            swal('Clik Tracker Redirects', res.message, 'success');
        }catch(e){
            console.log(e.message);
            swal(`Check your internet and try again`);
        }finally{
            setLoading(false);
        }
    }

    const onCountryAndUrl = () => {
        if(url.replace(/\s/gmi, '') === ""){
            return swal('Link Redirects', 'Please provide a redirect link', 'info');
        }

        if(country === "-1"){
            return swal('Link Redirects', 'Please provide a redirect link', 'info');
        }

        if(redirects.find(r=>r.code === country)){
            return swal('Link Redirects', `${lookup.byInternet(country).country} already exists`, 'info');
        }

        redirects.push({
            code:country,
            url:url
        });

        setRedirects([...redirects]);
    }

    const onDel = async (index) =>{
        try{
               
            const list = []
            redirects.forEach((r, i)=>{
                if(index !== i){
                    list.push(r);
                }
            });

            setRedirects(list);
        }catch(e){
            console.log(e.message);
        }finally{
            setLoading(false);
        }
    }

    const filter = (name)=>{
        return lookup.byCountry(name) !== null && lookup.byCountry(name) !== undefined;
    }



    if(loading){
        return (
            <div>
                <AppBar>
                    <Container>
                        <AnimationLoading title="Loading..."/>
                    </Container>
                </AppBar>
            </div>
        )
    }


    return (
        <AppBar>
           
            <Container className="centralise">
                <Breadcrumb>
                    <Breadcrumb.Item href="#"><Link to="/links">Clik Tracker</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{editingClikTracker.name} Redirects</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div className="centralise">
                    <Button variant="success" className="round hover dropShadow" onClick={onSet}>
                        <BiDirections />
                        <strong>Set Redirects</strong>
                    </Button>
                </div>
                <br/>
                <TextField style={{width:500}} type="url" label="Redirect Link" variant="outlined" value={url} onChange={e => setUrl(e.target.value)}/>
                <FormControl sx={{ m: 1, minWidth: 360 }}>
                    <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                    <Select labelId="demo-simple-select-helper-label" id="demo-simple-select-helper" value={country} label="Country" onChange={(e)=>setCountry(e.target.value)}>
                        <MenuItem disabled value="-1"><em>Choose Country</em></MenuItem>
                        {
                            CountriesAndCities.getCountries().filter(filter).map(country_name => <MenuItem value={lookup.byCountry(country_name).internet}>{country_name}</MenuItem> )
                        }
                    </Select>
                    <FormHelperText>Choose the country to apply redirect</FormHelperText>
                </FormControl> 
                <Button variant="success" className="dropShadow hover" onClick={onCountryAndUrl}>
                    <strong>ADD</strong>
                </Button>      
                </Container>
                <br/>
                <Table variant="success" className="dropShadow">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>COUNTRY</th>
                            <th>REDIRECT LINK</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            redirects.map((redirect, index) =>
                            <tr key={index+"redirect"}>
                                <th>{index+1}</th>
                                <th>
                                    <ReactCountryFlag svg countryCode={redirect.code} style={{fontSize: '2em',lineHeight: '2em', }} aria-label={redirect.code}/>
                                    {lookup.byInternet(redirect.code).country}
                                </th>
                                <th>{redirect.url}</th>
                                <th>
                                    <Button variant="success" className="hover dropShadow" onClick={()=>onDel(index)}>
                                        <AiFillDelete/>
                                    </Button>
                                </th>
                        </tr> 
                            )
                        }
                    </tbody>
                </Table> 
            
        </AppBar>
    )
}

export default PageLinkRedirects
